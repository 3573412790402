<template>
  <div class="card p-shadow-6">
    <h1>Áreas de Negócio</h1>
    <Toast :style="{ width: $isMobile() ? 'vw' : '' }" />
    <ConfirmDialog />
    <div style="height: 65vh">
      <DataTable
        :value="businessAreas"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-plus"
              @click="create"
              v-if="can('createBusinessArea')"
            />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome da Área"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span
              v-if="can('updateBusinessArea')"
              class="clickable-item"
              @click="edit(slotProps.data)"
              >{{ slotProps.data.name }}</span
            >
            <span v-else>{{ slotProps.data.name }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
      </DataTable>
      <Dialog
      :visible.sync="businessAreaModel.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (businessAreaModel.id == undefined ? 'Criar' : 'Actualizar') + ' Área de negócio'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="crmBusinessAreas" class="p-col-12" @submit.prevent="save">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-8">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="businessAreaModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('name') },
                  'form-control',
                ]"
                :name="'name'"
              />
              <label for="name">Nome</label>
            </span>
            <small v-if="errors.has('name')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import businessService from "../services/business.service";

export default {
  name: "businessAreaAdmin",
  data() {
    return {
      businessAreas: [],
      loading: false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      businessAreaModel: {
        show: false,
        id: null,
        name: null,
      },
    };
  },
  async created() {
    this.loading = true;
    this.businessAreas = await businessService.getBusinessAreas();
    this.loading = false;
  },
  methods: {
    closeForm() {
      this.businessAreaModel = { show: false, id: null, name: null };
    },
    async create() {
      return (this.businessAreaModel = {
        show: true,
      });
    },
    edit(data) {
      this.businessAreaModel = {
        show: true,
        id: data.id,
        name: data.name,
      };
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.businessAreaModel.id === undefined) {
          return this.saveNew();
        }

        return this.update();
      });
    },
    saveNew() {
      let params = {
        name: this.businessAreaModel.name,
      };
      this.loading = true;
      return businessService.createBusinessArea(params).
      then((response) => {
        this.loading = false;
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir a área de negócio",
            life: 3000,
          });
        }
        this.businessAreas.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Área de negócio criada",
          detail: "A área de negócio foi criada com sucesso",
          life: 3000,
        });
        return this.closeForm();
      });
    },
    update() {
      let params = {
        name: this.businessAreaModel.name,
      };

      this.loading = true;
      return businessService
        .updateBusinessArea(this.businessAreaModel.id, params)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao atualizar a área",
              life: 3000,
            });
          }
          let index = this.businessAreas.findIndex((c) => c.id == this.businessAreaModel.id)
          this.businessAreas[index].name = response.name;
          this.$toast.add({
            severity: "success",
            summary: "Área de negócio atualizada",
            detail: "A área foi atualizada com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
  },
};
</script>



<style scoped>
.datatable {
  margin: 20px;
}

.p-inputgroup {
  display: flex;
  align-items: center;
}

.pi-filter {
  margin-left: 8px;
  cursor: pointer;
}

.p-datatable-header {
  background-color: transparent!important;
}
</style>
